<template>
  <div v-if="reviews">
    <div
      class="canvas-reviews"
      v-if="reviews.tab !== 2 && reviews.tab !== 3 && reviews.type === 'lite'"
      :style="liningStyles"
    >
      <div class="canvas-reviews__list">
        <div
          v-html="reviews[landing.current_lang].header_text"
          style="min-height: 0; padding: 15px 0"
        ></div>
        <div
          class="canvas-reviews__item"
          v-for="review in reviews[landing.current_lang].questions"
          :key="review.id"
        >
          <h3 class="canvas-reviews__caption">{{ review.name }}</h3>
          <v-rating
            class="polls__stars"
            color="#ffac07"
            background-color="#e4e4e4"
            length="5"
            size="30"
          ></v-rating>
        </div>
        <div
          class="canvas-reviews__item"
          v-for="(textReview, index) in reviews[landing.current_lang]
            .text_questions"
          :key="index"
        >
          <h3 class="canvas-reviews__caption">{{ textReview.name }}</h3>
          <textarea
            class="canvas__textarea"
            :style="`color: ${landing.branding.font_color};border:1px solid #333;`"
          ></textarea>
        </div>

        <v-btn
          class="canvas__submit"
          depressed
          outlined
          small
          style="width: 100%"
          :style="`color:${
            reviews[landing.current_lang].btn_color
          };background-color:${
            reviews[landing.current_lang].btn_bg
          };border-color:${reviews[landing.current_lang].btn_border}`"
        >
          {{ reviews[landing.current_lang].btn_name }}
        </v-btn>
      </div>
    </div>
    <div v-if="reviews.tab === 0 || reviews.tab === 1">
      <div :style="liningStyles">
        <div
          v-html="reviews[landing.current_lang].header_text"
          style="min-height: 0; padding: 15px 0"
        ></div>
        <div
          v-for="reviewsGroup in reviews[current_lang].pro"
          :key="reviewsGroup.id"
          style="margin-bottom: 15px"
        >
          <label class="label" style="font-size: 18px">{{
            reviewsGroup.header
          }}</label>
          <div
            v-for="quest in reviewsGroup.questions"
            :key="quest.id"
            style="padding-left: 10px"
          >
            <div
              v-if="quest.name && quest.type === 'radio'"
              style="display: flex; align-items: center"
            >
              <label style="margin: 0 15px 0 0; width: 300px">{{
                quest.name
              }}</label>
              <v-checkbox
                on-icon="mdi-check-circle-outline"
                off-icon="mdi-checkbox-blank-circle-outline"
                :value="quest.value"
                type="checkbox"
                style="padding-top: 0"
                hide-details
              ></v-checkbox>
            </div>
            <div
              v-if="quest.name && quest.type === 'text'"
              style="display: flex; flex-direction: column; margin-bottom: 20px"
            >
              <label
                style="text-align: left"
                v-if="quest.name && quest.type === 'text'"
                >{{ quest.name }}</label
              >
              <v-textarea
                hide-details
                no-resize
                height="100"
                background-color="rgba(255,255,255,0.5)"
                solo
                v-if="quest.name && quest.type === 'text'"
              >
              </v-textarea>
            </div>
            <div
              v-if="quest.name && quest.type === 'star'"
              style="display: flex; flex-direction: column; margin-bottom: 15px"
            >
              <label
                style="text-align: left"
                v-if="quest.name && quest.type === 'star'"
                >{{ quest.name }}</label
              >
              <v-rating
                class="polls__stars"
                color="#ffac07"
                background-color="#e4e4e4"
                length="5"
                size="30"
              ></v-rating>
            </div>
          </div>
        </div>
        <v-btn
          class="hotel-reviews__submit"
          type="button"
          depressed
          outlined
          small
          :style="`color:${reviews.styles.btn_color};background-color:${reviews.styles.btn_bg};border-color:${reviews.styles.btn_border};`"
          style="
            width: 90%;
            margin: 0 auto;
            display: block;
            background-color: rgba(255, 255, 255, 0.5);
          "
        >
          {{ reviews.styles.btn_name }}
        </v-btn>
      </div>
    </div>
    <div v-if="reviews.tab === 2">
      <div :style="liningStyles">
        <div v-html="reviews.success_page[current_lang].content" />

        <div v-if="socials.length && reviews.show_success">
          <!--        <h2 class="hotel__links"  v-html="reviews.success_page[current_lang].second_content +' '+ socials.map((item) => item.text).join(', ')" />-->
          <div
            class="canvas-socials"
            :class="{
              'canvas-socials_tile':
                landing.reviews.socials.style === 'tile',
            }"
          >
            <a
              class="canvas-socials__item"
              v-external-link
              v-for="item in socials"
              :key="item.id"
              :href="item.link"
              rel="noopener noreferrer"
              target="_blank"
              :style="`background-color: ${item.color};
                                        border-radius: ${landing.reviews.socials.radius}px`"
            >
              <!-- <v-icon :color="'#fff'">{{item.icon}}</v-icon> -->
              <span
                v-if="item.icon"
                class="canvas-socials__icon canvas__fill"
                v-html="item.icon"
              ></span>
              {{ item.text }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="reviews.tab === 3">
      <div :style="liningStyles">
        <div v-html="reviews.unsuccess_page[current_lang].content" />
      </div>
    </div>
    <div v-if="reviews.tab === 4">
      <div
          :style="`margin-top:${
            landing.advanced.global.active_header_inside ||
            landing.advanced.global.active_logo_inside
                ? '20px'
                : `${+landing.branding.topMenu.styles.size * 2.5}px`
        };`"
        style="
          margin-bottom: 80px;
          background: #fff;
          padding: 15px;
          border-radius: 5px;
        "
      >
        <p>Title: {{ formatStr(reviews.emailNotification.header) }}</p>
          <br><br>
        <p v-html="formatStr(reviews.emailNotification.text)"></p>
      </div>
    </div>
    <div
        v-if="reviews.tab === 1"
        class="canvas-socials"
        :class="{
              'canvas-socials_tile': landing.socials.style === 'tile',
            }"
    >
      <a
          class="canvas-socials__item"
          v-for="item in landing.socials.list[landing.current_lang].filter(
                (x) => x.active
              )"
          :key="item.id"
          :href="item.link"
          v-external-link
          :style="`width:${
                landing.socials.style === 'tile'
                  ? `${+landing.branding.topMenu.styles.size * 2.5}px`
                  : landing.socials.width + '%'
              };background-color: ${item.color};
                                        border-radius: ${
                                          landing.socials.radius
                                        }px`"
      >
              <span
                  v-if="item.icon"
                  class="canvas-socials__icon canvas__fill"
                  v-html="item.icon"
              ></span>
        {{ item.text }}
      </a>
    </div>
  </div>
</template>

<script>
import  { mapState,mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "PreviewReviews",
  props: ["page", "landing"],
  components: {
  },
  mounted(){
    if(!this.reviews){
      this.$store.commit('reviews/setReviews',this.landing.reviews);
    }
  },
  computed: {
    ...mapGetters("landing", [
      "current_lang",
    ]),
    ...mapState('reviews',['reviews']),
    socials() {
      return this.reviews.socials.list.filter((item) => item.active);
    },
    liningStyles() {
        let styles = `padding:15px;margin-left:${
            this.landing.branding.lining_margin ? '10px' : '0'
        };margin-right:${this.landing.branding.lining_margin ? '10px' : '0'};margin-top:${
            this.landing.advanced.global.active_header_inside ||
            this.landing.advanced.global.active_logo_inside
                ? '20px'
                : `${+this.landing.branding.topMenu.styles.size * 2.5}px`
        };min-height:300px;`;
      if (this.landing.branding.bg_lining_img) {
        styles+= `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles+= `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
      return styles;
    },
  },
  methods: {
    formatStr(str) {
      if (str) {
        let sub = str;
        sub = sub.replace(":date:", moment().format("DD.MM.yyyy"));
        sub = sub.replace(":time:", moment().format("HH:mm"));
        return sub;
      }
    },
  },
};
</script>

<style lang="scss">
.hotel__links {
  margin-top: 20px;
  * {
    display: inline;
  }
}
</style>
